<!--
 * @Author: hsn
 * @Date: 2021-01-22 15:41:57
 * @LastEditTime: 2021-03-26 15:10:46
-->
<template>
  <div>
    <top></top>
    <search></search>
    <!-- 主体内容 -->
    <div class="bg_f4f4 Pbm10 Ptp10">
      <div class="contain">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>
            <span class="curPointer" @click="goHome()">首页</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-for="item in navList" :key="item.id">
            <span class="curPointer" @click="goMoreGoods(item.id)"
              >{{ item.categoryName }}
            </span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="contain Mbm10 Mtp10" id="detail">
      <div class="property">
        <div class="warp">
          <h1 class="F20">
            <span style="color: #999999; font-size: 14px">商品名称：</span>
            {{ basicInfo.title }}
          </h1>
          <div class="price-box">
            <span class="yen F22">
              <span style="color: #999999; font-size: 14px">单价：</span
              >￥</span
            >
            <span v-if="price == 0" class="price F34">面议</span>
            <span v-else class="price F34">{{ price }}</span>
          </div>
          <div class="F14 co_999 Mtp10">
            <span style="color: #999999; font-size: 14px">商品编码：</span>
            {{ goodsNo }}
          </div>
          <div class="subtitle co_999">规格分类</div>
          <!-- <el-tag type="info" v-if="specification == false">无可选规格</el-tag> -->
          <div class="goodsSellList">
            <ul>
              <li
                v-for="(item, index) in basicInfo.goodsSellList"
                :key="item.id"
                class="curPointer"
                :class="item.checked ? 'active' : ''"
                @click="goodsSellCheck(index)"
              >
                <el-image
                  v-show="item.logo !== ''"
                  :src="item.logo"
                  class="typeName"
                  :title="item.typeName"
                ></el-image>
                <!-- :img="item.logo"></pic-zoom> -->
                <!-- <img :src="item.logo" class="typeName" :title="item.typeName" /> -->
                <!--                 <div class="F12">{{ item.typeName }}</div>-->
                <div class="F12 goods-sellList-name" :title="item.typeName">
                  {{ item.typeName }}
                </div>
              </li>
            </ul>
          </div>
          <div class="F14 co_999 Mtp10">
            交货方式：{{ $matching(delivery, modeOfDelivery) }}
          </div>
          <div class="subtitle co_999">购买数量</div>
          <div>
            <el-input-number
              size="mini"
              :min="1"
              :max="inventory"
              :precision="3"
              v-model="number"
              label="购买数量"
            ></el-input-number>
            <!-- <span class="co_999 F14 Mlf10"></span> -->
            <span class="co_999 F14 Mlf10">{{ unit }}/单位</span>
            <span class="co_999 F14 Mlf20" v-if="inventory"
              >库存：{{ inventory }}</span
            >
          </div>
          <div class="price-box Mtp15">
            <span class="yen co_primary F18">
              <span style="color: #999999; font-size: 14px">总价：</span
              >￥</span
            >
            <span v-if="priceTotal == 0" class="price co_primary F32"
              >面议</span
            >
            <span v-else class="price co_primary F32">{{ priceTotal }}</span>
          </div>
          <div v-if="userInfo.roleType == '1'" class="Mtp5">
            <el-alert
              title="您的账号无法下单"
              type="warning"
              description="请登录或者注册采购商账号再进行操作！"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div v-else>
            <div v-if="basicInfo.goodStatus == '6'">
              <div class="Mtp20 noBorderRadius">
                <el-button type="info" plain disabled>
                  <span class="FBold F14">商品未上架</span>
                </el-button>
              </div>
            </div>
            <div v-else>
              <div v-if="userInfo.authentication == 'Y'">
                <div class="Mtp20 noBorderRadius" v-if="inventory">
                  <el-button type="primary" plain @click="placeOrder">
                    <span class="FBold F14">立即下单</span>
                  </el-button>
                  <el-button type="primary" @click="addCar">
                    <span class="FBold F14">加入购物车</span>
                  </el-button>
                </div>
                <div class="Mtp20 noBorderRadius" v-else>
                  <el-button type="info" plain disabled>
                    <span class="FBold F14">暂时缺货</span>
                  </el-button>
                </div>
              </div>
              <div v-else>
                <el-alert
                  title="您的账号暂时无法下单"
                  type="warning"
                  description=""
                  show-icon
                  :closable="false"
                >
                </el-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gallery">
        <div v-if="basicInfo.goodsLogoList.length > 0">
          <!--                    <splide :slides="basicInfo.goodsLogoList" :options="primaryOptions" ref="primary" class="goods_img_big">-->
          <!--                        <splide-slide v-for="item in basicInfo.goodsLogoList" :key="item.id">-->
          <!--                            <pic-zoom :img="item.logo"></pic-zoom>-->
          <!--                            &lt;!&ndash; <el-image :src="item.logo" /> &ndash;&gt;-->
          <!--                        </splide-slide>-->
          <!--                    </splide>-->

          <!--                    <splide :slides="basicInfo.goodsLogoList" :options="secondaryOptions" ref="secondary">-->
          <!--                        <splide-slide v-for="item in basicInfo.goodsLogoList" :key="item.id">-->
          <!--                            <el-image :src="item.logo" />-->
          <!--                        </splide-slide>-->
          <!--                    </splide>-->
          <Splide
            :options="primaryOptions"
            ref="primary"
            :slides="basicInfo.goodsLogoList"
            class="goods_img_big"
          >
            <SplideSlide v-for="item in basicInfo.goodsLogoList" :key="item.id">
              <PicZoom :img="image" />
            </SplideSlide>
          </Splide>
          <splide
            :options="secondaryOptions"
            ref="secondary"
            :slides="basicInfo.goodsLogoList"
          >
            <SplideSlide v-for="item in basicInfo.goodsLogoList" :key="item.id">
              <el-image :src="image" />
            </SplideSlide>
          </splide>
        </div>
        <!-- <el-carousel trigger="click" height="400px" :loop="false">
                  <el-carousel-item v-if="image">
                    <el-image :src="image" style="width: 400px"></el-image>
                    <pic-zoom :url="image" :scale="3"></pic-zoom>
                  </el-carousel-item>
                  <el-carousel-item
                    v-for="item in basicInfo.goodsLogoList"
                    :key="item.id"
                  >
                    <el-image :src="item.logo" style="width: 400px"></el-image>
                  </el-carousel-item>
                </el-carousel> -->
      </div>
    </div>
    <div class="contain" id="description">
      <div class="title bg_f4f4">
        <ul>
          <li class="active">商品详情</li>
        </ul>
      </div>
      <div class="goodsParameters">
        <ul>
          <li v-for="(item, index) in basicInfo.goodsPropertyList" :key="index">
            {{ item.propertyName }}:&nbsp;
            <span>{{ item.propertyValue }}</span>
          </li>
        </ul>
      </div>
      <div
        class="moreInformation"
        v-html="unescape(basicInfo.goodesDetail)"
      ></div>
    </div>
    <bottom class="bottom"></bottom>
  </div>
</template>
<script>
import Bottom from "@/components/bottom.vue";
import Search from "@/components/search.vue";
import top from "@/components/top.vue";
//轮播图
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
//放大镜
import PicZoom from "@/components/picZoom/com/PicZoom";
//  import PicZoom from 'vue-piczoom'

import { getShopDetailInfo, getSellShopDetailInfo } from "@/api/website.js";
import { addCar } from "@/api/user.js";
import {
  getGoodsTypeNavigationByGoodsId,
  getGoodsTypeNavigationByGoodsNo,
} from "@/api/product";
import { mapGetters } from "vuex";
import { modeOfDelivery } from "@/util/util";

import _ from "lodash";
import BigNumber from "bignumber.js";
export default {
  components: {
    top,
    Search,
    Bottom,
    Splide,
    SplideSlide,
    PicZoom,
  },
  name: "GoodDetail",
  data() {
    return {
      modeOfDelivery,
      allImage: [],
      primaryOptions: {
        type: "loop",
        // type: "fade",
        width: 400,
        height: 400,
        autoplay: true,
        pagination: false,
        gap: "1rem",
        perMove: 1,
        perPage: 1,
        cover: true,
        arrows: false,
        drag: true,
        rewind: true,
      },
      secondaryOptions: {
        type: "slide",
        rewind: true,
        gap: "1rem",
        autoplay: true,
        drag: true,
        pagination: false,
        width: 400,
        focus: "center",
        fixedWidth: 70,
        // fixedHeight: 70,
        cover: true,
        isNavigation: true,
        // arrows: false,
      },
      id: "",
      no: "",
      basicInfo: {
        goodsLogoList: [],
        goodsSellList: [],
        goodsPropertyList: [],
        goodsTypeList: [],
      },
      price: 0,
      goodsNo: "",
      number: 1,
      unit: "",
      goodsSellId: "",
      inventory: null,
      image: "",
      // specification: false,
      navList: [],
      delivery: "1",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    priceTotal() {
      let priceTotal = 0;
      for (let x = 0; x < this.basicInfo.goodsSellList.length; x++) {
        let result = this.basicInfo.goodsSellList[x];
        if (result.checked) {
          let price = Number(result.price);
          let number = Number(this.number);
          //修改四舍五入，两位数
          // priceTotal = price * number;
          priceTotal = new BigNumber(price).multipliedBy(new BigNumber(number));
        }
      }
      return priceTotal.toFixed(2);
    },
  },
  mounted() {
    // this.$refs.secondary.sync(this.$refs.primary.splide);

    if (this.$route.query.no) {
      this.no = this.$route.query.no;
      this.getShopDetailInfo(this.no);
      this.getGoodsTypeNavigation(this.no);
    } else if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getShopDetailInfo(this.id);
      this.getGoodsTypeNavigation(this.id);
    }
  },
  methods: {
    goMoreGoods(typeId) {
      this.$router.push({
        name: "MoreGoods",
        query: {
          typeId: typeId,
        },
      });
    },
    goHome() {
      this.$router.push({
        name: "index",
      });
    },
    // 获取商品的详细信息
    getShopDetailInfo(query) {
      let method = this.$route.query.no
        ? getSellShopDetailInfo
        : getShopDetailInfo;
      method(query).then((res) => {
        let response = res.data;
        // 处理goodsSellList数据
        // 1、将goodsSellList每一项加入checked，来判断选中哪一个
        this.unit =
          res.data.goodsSellList.length > 0
            ? res.data.goodsSellList[0].sellUnit
            : "件";
        for (let x = 0; x < response.goodsSellList.length; x++) {
          let result = response.goodsSellList[x];
          if (x == 0) {
            result.checked = true;

            this.goodsNo = result.goodsNo;
            this.goodsSellId = result.id;
            this.inventory = result.inventory;
            this.delivery = result.delivery;
          } else {
            result.checked = false;
          }
        }
        //判断有无规格
        // for (let x = 0; x < response.goodsSellList.length; x++) {
        //   let result = response.goodsSellList[x];
        //   if (result.logo !== "") {
        //     this.specification = true;
        //     console.log("规格" + this.specification);
        //   }
        // }

        this.image = response.goodsLogoList[0].logo;
        this.$set(this, "basicInfo", response);
        this.$nextTick(function () {
          // 这里再调用方法，
          this.$refs.primary.sync(this.$refs.secondary.splide);
        });
        // 设置金额
        this.getPrice(this.basicInfo.goodsSellList);
        //获取主图和规格图片
        // this.allImage = response.goodsLogoList.concat(response.goodsSellList);
        // var listLogo = this.allImage
        // var resu = [];
        // var hash = {};
        // for (var i = 0; i < listLogo.length; i++) {
        //   var elem = listLogo[i].logo;
        //   var elem = listLogo[i].logo;
        //   console.log("logoList是"+elem)
        //   if (!hash[elem]) {
        //     resu.push(listLogo[i]);
        //     hash[elem] = true;
        //   }
        // }
      });
    },
    // 规格选中事件
    goodsSellCheck(index) {
      this.basicInfo.goodsSellList.forEach((value) => {
        value.checked = false;
      });
      this.basicInfo.goodsSellList[index].checked = true;
      this.price = this.basicInfo.goodsSellList[index].price;
      this.goodsNo = this.basicInfo.goodsSellList[index].goodsNo;
      this.goodsSellId = this.basicInfo.goodsSellList[index].id;
      this.inventory = this.basicInfo.goodsSellList[index].inventory;
      this.image = this.basicInfo.goodsSellList[index].logo;
      this.delivery = this.basicInfo.goodsSellList[index].delivery;
    },
    // 获取价格区间
    getPrice(arr) {
      if (arr.length == 0) {
        this.price = 0;
      } else {
        // 升序
        let minNumber = _.sortBy(arr, ["price"], ["asc"])[0].price;
        // 降序
        let maxNumber = _.sortBy(arr, ["price"], ["desc"])[0].price;

        if (minNumber == maxNumber) {
          this.price = minNumber;
        } else {
          this.price = minNumber + "-" + maxNumber;
        }
      }
    },
    addCar() {
      let data = {
        userId: this.userInfo.id,
        goodsId: this.id,
        goodsSellId: this.goodsSellId,
        amount: this.number,
      };
      addCar(data).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "加入购物车成功！",
          });
          // 获取购物车列表，获取购物车总数
          this.$store.dispatch("myCartList", this.userInfo.id);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    /*
     *@functionName:下单操作
     *@params1: 参数1
     *@params2: 参数2
     *@params3: 参数3
     *@params4: 参数4
     *@description:
     *@author: huangshengnan
     *@date: 2021-02-26 12:07:59
     *@version: V1.0.0
     */
    placeOrder() {
      this.$router.push({
        name: "OrderSettlement",
        query: {
          type: "goodsDetail",
          ids: this.id,
          goodsSellId: this.goodsSellId,
          number: this.number,
        },
      });
    },
    unescape(html) {
      return unescape(html);
    },

    // 前往认证
    goAuthentication() {
      this.$router.push({
        name: "Authentication",
      });
    },
    // 获取面包屑导航数据
    getGoodsTypeNavigation(query) {
      let method = this.$route.query.no
        ? getGoodsTypeNavigationByGoodsNo
        : getGoodsTypeNavigationByGoodsId;
      if (this.$route.query.no) {
        getGoodsTypeNavigationByGoodsNo({ goodsNo: query }).then((res) => {
          this.$set(this, "navList", res.data);
        });
      } else {
        getGoodsTypeNavigationByGoodsId({ goodsId: query }).then((res) => {
          this.$set(this, "navList", res.data);
        });
      }
    },
    // 面包学导航点击事件
    navClick(typeId) {
      this.$router.push({
        name: "MoreGoods",
        query: {
          typeId: typeId,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.goods_img_big {
  .choose {
    margin: 0 !important;
  }
}
</style>
<style scoped lang="scss">
@import "@/style/variables.scss";
#detail {
  margin-top: 35px;
  overflow: hidden;
  min-height: 500px;
  .property {
    float: left;
    width: 100%;
    .warp {
      margin: 0 0 0 450px;
    }
  }
  .gallery {
    // border: 1px solid black;
    float: left;
    margin-left: -100%;
    width: 400px;
    position: relative;
  }
  .price-box {
    color: $primaryColor;
    .yen {
      vertical-align: middle;
      font-weight: bolder;
      display: inline-block;
      margin-bottom: -9px;
    }
    .price {
      vertical-align: middle;
      font-weight: bolder;
    }
  }
  .subtitle {
    font-size: 14px;
    padding: 13px 0;
  }
}
$titleHeight: 52px;
#description {
  margin-bottom: 25px;
  .title {
    height: $titleHeight;
    line-height: $titleHeight;
    ul {
      margin: 0px;
      padding: 0px;
      overflow: hidden;
      li {
        float: left;
        display: block;
        padding: 0 20px;
        &.active {
          background-color: $primaryColor;
          color: #ffffff;
        }
      }
    }
  }
  .goodsParameters {
    padding: 30px 0;
    ul {
      margin: 0px;
      padding: 0px;
      overflow: hidden;
      li {
        font-size: 14px;
        padding: 7px 0px;
        width: 25%;
        float: left;
        display: block;
        text-indent: 2em;
        color: #999999;
        span {
          color: #333;
        }
      }
    }
    .moreLink {
      text-align: right;
      padding: 10px 20px 0 0;
    }
  }
  .moreInformation {
    width: 790px;
    margin: 45px auto;
  }
}
.goodsSellList {
  ul {
    display: inline-block;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    li {
      text-align: center;
      float: left;
      margin-right: 10px;
      .typeName {
        width: 55px;
        height: 55px;
        border: 2px solid #ccc;
      }
      &.active .typeName {
        border: 2px solid $primaryColor;
      }
      &.active {
        color: $primaryColor;
        font-weight: bolder;
      }
    }
  }
}
.goods-sellList-name {
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
